import '../App.css';

function AboutText() {
    return (
        <div class="time-left-wrapper">
       
Welcome to beautiful Ojai, California.  Home of the Pink Moment.
<br/><br/>
Ojai lies in a rare east/west valley. Most mountain ranges run on a north/south axis which means that most valleys run north and south. Our valley catches the late western sun rather than blocking it out.
<br/><br/>

As the sun starts to set over the Pacific Ocean, its light travels a long way through the atmosphere and refracts into marvelous colors upon the Topatopa Bluffs that tower over the Ojai Valley. The naturally reddish rocky faces of these bluffs create a perfect canvas for light that is refracted and possibly also reflected by the ocean. For a few fleeting moments the Topatopa mountains illuminate with glorious shades of pink along with bands of orange and red.
<br/><br/>

While the sun does set daily, the Pink Moment is not always visible due to clouds, precipitation, or other impediments that block the sun's light from reaching our 6,000+ foot mountains.
<br/><br/>

If you have a photo of the PinkMoment that you'd like share, please tag us on Instagram @ojaipinkmoment.
    </div>
  );
}

export default AboutText;
